import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Modal, Switch, TextField, Tooltip } from "@material-ui/core";
import {activateTasks,deleteTask, getdevice } from "../../Services/api";
import loaderimg from "../../assets/spinner.gif"
import { useSelector } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconButton } from "@material-ui/core";


const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.lightgreen,
    // color: theme.palette.common.white,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const GreenSwitch = withStyles({
  switchBase: {
    color: "lightgreen",
    '&$checked': {
      color: "green",
    },
    '&$checked + $track': {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {},
  heading: {
    fontWeight: 600,
  },
  paper: {
    position: 'absolute',
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxHeight:"70vh",
    overflowY:"scroll"
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

 

export default function TaskTable({tasks,loader,getfarmdata,handleDelete,handleEdit}) {
  const classes = useStyles();
  const current_farm = useSelector(state=> state.farmReducer.current);
  
  
  // const [name, setName] = React.useState("");
  // const [id, setId] = React.useState("");


  // const body = (
  //   <div  className={classes.paper}>
  //     <h2 id="simple-modal-title">Please Enter Name</h2>
  //     <CssTextField onChange={(e)=>setName(e.target.value)} value={name} fullWidth variant="outlined" label="Name" size="small" style={{marginTop:20}}/>
  //     <Button onClick={addName} variant="contained" color="primary" style={{color:"white",marginTop:10,width:150}}>Submit</Button>
  //   </div>
  // );
  
  const handleSwitch=(task_id,newstatus)=>{
    // setLoader(false)
    activateTasks(current_farm,task_id,newstatus)
    .then(res=>{
      console.log("delete",res.data)
      getfarmdata()
    })
    .catch(e=>console.log(e))
  }
  
  const SummaryTable=({task})=>{
    const [formatted, setFormatted] = React.useState([]);
    React.useEffect(()=>{
      console.log(task)
      getdevice()
      .then(res=>{
      
      let selectconttemp = []
      res.data.outputs.map(item=>{
        task.outputs.map(cont=>{
          if(cont.output_id===item._id)
            selectconttemp.push({...item,ports:cont.ports})
        })
      })
      console.log(selectconttemp)
      setFormatted(selectconttemp)
      })
      .catch(e=>console.log(e))
      
    },[])
    return(
      <TableContainer component={Paper} style={{margin:10}}>
      <Table className={classes.table} aria-label="customized table" fullwidth>
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.heading} >
              Controllers
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Ports
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Running Interval
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              {task.config.on_time? "On Time":"Resume Time"}
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
            {task.config.off_time? "Off Time":"Stop Time"}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {
            formatted.map((item) => (
              <StyledTableRow>
                <StyledTableCell>
                  {item.name}
                </StyledTableCell>
                <StyledTableCell>
                  {item.ports.join()}
                </StyledTableCell>
                <StyledTableCell>
                  {task.timing.start_time+" / "+task.timing.end_time}
                </StyledTableCell>
                <StyledTableCell>
                  {task.config.on_time?task.config.on_time:task.config.resume_time}
                </StyledTableCell>
                <StyledTableCell>
                  {task.config.off_time?task.config.off_time:task.config.stop_time}
                </StyledTableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
    )

  }


  const SummaryRow=({item})=>{
    const [open, setOpen] = React.useState(false);
    return(
            <>
              <StyledTableRow key={item._id} >
                <StyledTableCell component="th" scope="row">
                  {item.name}
                </StyledTableCell>
                <StyledTableCell >{item.mode}</StyledTableCell>
                <StyledTableCell >{item.repeat}</StyledTableCell>
                <StyledTableCell >
                  <Tooltip title="delete" style={{cursor:'pointer'}}><DeleteIcon onClick={()=>{handleDelete(item._id)}} /></Tooltip>
                  <Tooltip title="edit" style={{cursor:'pointer',marginLeft:10}}><EditIcon onClick={()=>{
                      handleEdit(item)
                    }}/></Tooltip>
                </StyledTableCell>
                <StyledTableCell >
                    <GreenSwitch checked={item.isActive} onChange={()=>handleSwitch(item._id,!item.isActive)}/>
                </StyledTableCell>
                <StyledTableCell width={50}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow style={{backgroundColor:"#ededed"}}>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography gutterBottom variant="h5" style={{margin:10,fontWeight:'bold'}}>
                        Summary
                      </Typography>
                      <SummaryTable task={item}/>
                    </Box>
                  </Collapse>
                </StyledTableCell>
              </StyledTableRow>
            </>
          )
  }

  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" fullwidth>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={1} className={classes.heading} >
              Task Name
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Mode
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Repeat
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Actions
            </StyledTableCell>
            <StyledTableCell className={classes.heading} >
              Status
            </StyledTableCell>
            <StyledTableCell className={classes.heading} ></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
          {loader?tasks.map((item) => (
            <SummaryRow item={item}/>
          )):
          <StyledTableRow>
            <StyledTableCell colSpan={5} align="center">
              <img src={loaderimg} alt="loader"/>
            </StyledTableCell>
          </StyledTableRow>
          
            
          }
        </TableBody>
      </Table>
      {/* <Modal
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          {body}
      </Modal> */}
    </TableContainer>
  );
}
