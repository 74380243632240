import React from "react";
import { Container, makeStyles, Box, Button,Stepper,Step,StepButton,StepConnector,Typography, Modal,TextField, StepLabel, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, InputAdornment, TableContainer, Table, Paper, TableHead, TableRow, TableBody, Tooltip } from "@material-ui/core";
import TaskTable from "./TaskTable";
import { withStyles } from "@material-ui/styles";
import { getSensors,addTask, getTasks,deleteTask, updateTask } from "../../Services/api";
import { useSelector } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from '@material-ui/icons/Delete';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.lightgreen,
    // color: theme.palette.common.white,
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);



const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "95vh",
    marginBottom: 0,
    marginTop: theme.spacing(1),
  },
  add: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20,
  },
  paper: {
    position: 'absolute',
    width: 900,
    height:"100vh",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxHeight:"70vh",
    overflowY:"scroll",
    border:"none"
  },
  formControl: {
    minWidth: 200,
  },
  button:{
    color:"white"
  },
}));

function getSteps() {
  return ['Basic Task info', 'Select Controllers', 'Configurations'];
}


let portarray=[{name:"P1",value:false},{name:"P2",value:false},{name:"P3",value:false},{name:"P4",value:false},
{name:"P5",value:false},{name:"P6",value:false},{name:"P7",value:false},{name:"P8",value:false},{name:"P9",value:false},{name:"P10",value:false}];

const TaskView = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [snackbar,setSnackbar]=React.useState(false);
  const [name, setName] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [mode, setMode] = React.useState("AMB");
  const [repeat, setRepeat] = React.useState("DAILY");
  const [current_controller, setCurrent_controller] = React.useState("");
  const [current_sensor, setCurrent_sensor] = React.useState("");
  const [sensors, setSensors] = React.useState([]);
  const [controllers, setControllers] = React.useState([]);
  const [selectedcontrollers, setSelectedcontrollers] = React.useState([]);
  const [type, setType] = React.useState("T");
  const [ports, setPorts] = React.useState(portarray);
  const [start_time, setStart_time] = React.useState("");
  const [end_time, setEnd_time] = React.useState("");
  const [ontime, setOntime] = React.useState("");
  const [offtime, setOfftime] = React.useState("");
  const [temp, setTemp] = React.useState("");
  const [hum, setHum] = React.useState("");
  const [toperator,setToperator] = React.useState("LESS THAN EQUAL TO")
  const [hoperator,setHoperator] = React.useState("LESS THAN EQUAL TO")
  const [selectedcondition,setSelectedcondition] = React.useState([])
  const [selectedsensors,setSelectedsensors] = React.useState([])
  const [error1, setError1] = React.useState("");
  const [error2, setError2] = React.useState("");
  const [error3, setError3] = React.useState("");

  const[updatetaskid,setUpdatetaskid] = React.useState("")
  
  const [tasks,setTasks]= React.useState([])
  const [loader,setLoader]= React.useState(false)

  const current_farm = useSelector(state=> state.farmReducer.current);


  
  React.useEffect(()=>{
    if(current_farm)
      getfarmdata()
  },[current_farm])


  const getfarmdata=()=>{
    getTasks(current_farm)
    .then(res=>{
      setLoader(true)
      setTasks(res.data.tasks)})
    .catch(e=>console.log(e))
  }


  const steps = getSteps();
  const handleNext = () => {
    setError1("")
    setError2("")
    if(activeStep===0){
      if(!name){
        setError1("Please Enter a Name")
        return
      }
      else if(!start_time||!end_time){
        setError1("Please Enter Time correctly")
        return
      }
    }    
    else if(activeStep===1){
      if(!selectedcontrollers.length){
        setError2("Please Select alteast one controller")
        return
      }
    }   
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleModal=()=>{
    
    getSensors(current_farm)
    .then(res=>{
      console.log(res.data)
      setControllers(res.data.outputs)
      setSensors(res.data.sensors)
    })
    .catch(e=>console.log(e))
    setOpen(true);
  }
  
  const handleChecked=(name)=>{
    let temp= ports.map(item=>{
      if(item.name===name)
      return(
        {
          ...item,
          value:!item.value
        }
      )
      return item
    })
    setPorts(temp)
  }

  const handleSelect = () => {
    setError2("")
    let tempport = []
    ports.map(item => {
      if (item.value)
      tempport.push(item.name)
    })


    if(!current_controller){
      setError2("please select a controller")
    }
    else if(!tempport.length){
      setError2("please select a port")
    }
    else{
    let tempname;
    controllers.map(item=>{
      if(current_controller===item._id)
      tempname=`${item.name?item.name:""} (${item.mac_addr})`
    })

    
    setSelectedcontrollers([...selectedcontrollers, { output_id: current_controller,name:tempname, ports: tempport }])
    setControllers(controllers.filter(item=>item._id!==current_controller))
    setCurrent_controller("")
    setPorts(portarray)
    
   }
  }
  const closeModal=()=>{
    setCurrent_controller("")
    setPorts(portarray)
    setSelectedcontrollers([])
    setSelectedcondition([])
    setControllers([])
    setSensors([])
    setSelectedsensors([])
    setName("")
    setOfftime("")
    setHum("")
    setTemp("")
    setToperator("LESS THAN EQUAL TO")
    setHoperator("LESS THAN EQUAL TO")
    setOntime("")
    setMode("AMB")
    setRepeat("DAILY")
    setActiveStep(0)
    setStart_time("")
    setEnd_time("")
    setOpen(false)
    setError1("")
    setError2("")
    setError3("")
    setUpdatetaskid("")
  }

  // const convertTime=(input)=>{
  //   let time=input.split(":")
  //   let hours =time[0].length==1?(time[0]==0?"12":"0"+time[0]):(parseInt(time[0])%12)>9?parseInt(time[0])%12:parseInt(time[0])%12==0?"12":"0"+parseInt(time[0])%12;
  //   let minute=time[1].length==1?"0"+time[1]:time[1]
  //   let ampm =parseInt(time[0])>11?" pm":" am"
  //   time=`${hours}:${minute}${ampm}`
  //   return time
  // }

  const handleSelectconditions =()=>{
    setError3("")

      if(type==='TH'){
        if(!temp)
        setError3("Please Enter a Temperature Value")
        else if(!hum)
        setError3("Please Enter a Humidity Value")
        else
        setSelectedcondition([...selectedcondition, { type:'T',value:temp,operator:toperator },{ type:'H',value:hum,operator:hoperator }]);
      }
      else if(type==='T'){
        if(!temp)
        setError3("Please Enter a Temperature Value")
        else
        setSelectedcondition([...selectedcondition, { type:'T',value:temp,operator:toperator }]);
      }
      else if(type==='H'){
        if(!hum)
        setError3("Please Enter a Humidity Value")
        else
        setSelectedcondition([...selectedcondition, { type:'H',value:hum,operator:hoperator }]);
      }
      
      setType("T");
      setTemp("")
      setToperator("LESS THAN EQUAL TO")
      setHum("")
      setHoperator("LESS THAN EQUAL TO")
      setCurrent_sensor("")
    
  }


  const handleSelectsensors=()=>{
    setError3("")
    if(!current_sensor){
      setError3("Please select a Sensor")
    }
    else{
    let tempname;
    sensors.map(item=>{
      if(current_sensor===item._id)
      tempname=`${item.name?item.name:""} (${item.serial_num})`
    })
    setSelectedsensors([...selectedsensors,{name:tempname,id:current_sensor}])
    setSensors(sensors.filter(item=>item._id!==current_sensor))
    setCurrent_sensor("")
    }
  }

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const handleSubmit =()=>{

    if(!isNumeric(ontime)||!isNumeric(offtime))
      setError3(mode!=='CYC'?"Check the values for Resume and Stop time":"Check values for ON and Off time");
    else if(!selectedcondition.length&& mode==='AMB')
      setError3("Please Add Atleast One Condition")
    else if(!selectedsensors.length&& mode==='AMB')
      setError3("Please Add Atleast One Sensor")
    else if(!selectedsensors.length&& mode==='AMB')
      setError3("Please Add Atleast One Sensor")
    else if(parseInt(ontime) > 9999 || parseInt(ontime) < 10 || parseInt(offtime) < 1 || parseInt(offtime)>9999)
      setError3(mode!=='CYC'?"Check the Limits for Resume and Stop time":"Check Limits for ON and Off time")
    else{
      let packet={
         farm_id:current_farm,
         name,
         mode,
         repeat,
         outputs:selectedcontrollers.map(({name,...rest})=>rest),
         timing:{start_time,end_time},
      }
      if(mode==='CYC'){
        packet.config={
          on_time:ontime,
          off_time:offtime
        }
      }
      else if(mode==='RTC'){
        packet.config={
          resume_time:ontime,
          stop_time:offtime
        }
      }
      else if(mode==='AMB'){
        packet.config={
          resume_time:ontime,
          stop_time:offtime,
          sensors:selectedsensors.map(item=>item.id),
          conditions:selectedcondition,
        }
      }
      console.log("finish",packet)


      addTask(packet)
      .then(res=>{
        console.log("res",res.data)
        closeModal();
        getfarmdata();
        setSnackbar(true)
      })
      .catch(e=>console.log(e))
    }
  }

  const handleDelete =(task_id)=>{
    if (window.confirm("Are you sure you want to delete this task?")){
      deleteTask(current_farm,task_id)
    .then(res=>{

      getfarmdata()
    })
    .catch(e=>console.log(e))
    }
    
  }


  const handleUpdate=()=>{
    console.log("time",ontime,offtime)
    if(!isNumeric(ontime)||!isNumeric(offtime))
      setError3(mode!=='CYC'?"Check the values for Resume and Stop time":"Check values for ON and Off time");
    else if(!selectedcondition.length&& mode==='AMB')
      setError3("Please Add Atleast One Condition")
    else if(!selectedsensors.length&& mode==='AMB')
      setError3("Please Add Atleast One Sensor")
    else if(parseInt(ontime) > 9999 || parseInt(ontime) < 10 || parseInt(offtime) < 1 || parseInt(offtime)>9999)
      setError3(mode!=='CYC'?"Check the Limits for Resume and Stop time":"Check Limits for ON and Off time")
    else{
      let packet={
         task_id: updatetaskid,
         farm_id: current_farm,
         name,
         mode,
         repeat,
         outputs:selectedcontrollers.map(({name,_id,mac_addr,serial_num,created_at,updated_at,...rest})=>({...rest,output_id:_id})),
         timing:{start_time,end_time},
      }
      if(mode==='CYC'){
        packet.config={
          on_time:ontime,
          off_time:offtime
        }
      }
      else if(mode==='RTC'){
        packet.config={
          resume_time:ontime,
          stop_time:offtime
        }
      }
      else if(mode==='AMB'){
        packet.config={
          resume_time:ontime,
          stop_time:offtime,
          sensors:selectedsensors.map(item=>item._id),
          conditions:selectedcondition,
        }
      }

      console.log(packet)


      updateTask(packet)
      .then(res=>{
        console.log("res",res.data)
        closeModal();
        getfarmdata();
        setSnackbar(true)
      })
      .catch(e=>console.log(e))
    }

  }



  const handleEdit=(task)=>{
    setUpdatetaskid(task._id)


    getSensors(current_farm)
    .then(res=>{

      setControllers(res.data.outputs)
      setSensors(res.data.sensors)

      setName(task.name)
      setMode(task.mode)
      setRepeat(task.repeat)
      setStart_time(task.timing.start_time)
      setEnd_time(task.timing.end_time)

      let selectconttemp = []
      let othercont = []


      
      res.data.outputs.map(item=>{
        task.outputs.map(cont=>{
          if(cont.output_id===item._id)
            selectconttemp.push({...item,ports:cont.ports})
          else
            othercont.push(item)
        })
      })
      setSelectedcontrollers(selectconttemp)
      setControllers(othercont)


      

      if(task.mode==='CYC'){
        setOntime(task.config.on_time)
        setOfftime(task.config.off_time)
      }
      else if(task.mode==='RTC'){

        setOntime(task.config.resume_time)
        setOfftime(task.config.stop_time)
      }
      else if(task.mode==='AMB'){

      let selectsenstemp = []
      let othersens = []


      
      res.data.sensors.map(item=>{
        task.config.sensors.map(cont=>{
          if(cont===item._id)
            selectsenstemp.push({...item,ports:cont.ports})
          else
            othersens.push(item)
        })
      })
      setSelectedsensors(selectsenstemp)
      setSensors(othersens)

        setOntime(task.config.resume_time)
        setOfftime(task.config.stop_time)
        setSelectedsensors(res.data.sensors.filter(item=>task.config.sensors.includes(item._id)))
        setSelectedcondition(task.config.conditions)
      }
    })
    .catch(e=>console.log(e))

   
    setOpen(true);

  }

  const handleUnselect=(task_id)=>{
    console.log(task_id)
    let newselect = selectedcontrollers.filter(item=>{
      if(item._id === task_id){
          setControllers([...controllers,item])
          return false
      }
      return true
    })
    setSelectedcontrollers(newselect)

  }

  const deleteCondition=(condn)=>{
    setSelectedcondition(selectedcondition.filter(item=>{
      if(condn.type == item.type && condn.value == item.value && condn.operator == item.operator){
        return false
      }
      return true
    }))

  }

  const deleteSensor=(sensor_id)=>{
    setSelectedsensors(selectedsensors.filter(item=>{
      if(sensor_id == item._id){
        setSensors([...sensors,item])
        return false
      }
      return true
    }))

  }



  
  
  const step1=(
    <div>
      <CssTextField onChange={(e)=>setName(e.target.value)} value={name} fullWidth variant="outlined" label="Name" size="small"/>
      <div style={{display:"flex",alignItems:"center"}}>
      <FormControl className={classes.formControl} variant="outlined" size="small" style={{marginTop:20}}>
        <InputLabel id="mode">Mode</InputLabel>
        <Select
          label="Mode"
          labelId="mode"
          id="demo-simple-select"
          value={mode}
          onChange={(e)=>setMode(e.target.value)}
        >
          <MenuItem value={"AMB"}>Ambient Mode</MenuItem>
          <MenuItem value={"CYC"}>Cyclic Mode</MenuItem>
          <MenuItem value={"RTC"}>Real Time Mode</MenuItem>
        </Select>
        
      </FormControl>
      <FormControl className={classes.formControl} variant="outlined" size="small" style={{marginTop:20,marginLeft:20}}>
        <InputLabel id="repeat">Repeat</InputLabel>
        <Select
          label="Type"
          labelId="Repeat"
          id="demo-simple-select"
          value={repeat}
          onChange={(e)=>setRepeat(e.target.value)}
        >
          <MenuItem value={"DAILY"}>Daily</MenuItem>
          <MenuItem value={"ONCE"}>Once</MenuItem>
        </Select>
      </FormControl>

      </div>
      <h5>Select Timing</h5>
      <div style={{marginTop:20}}>
        <CssTextField
          label="Start time"
          type="time"
          variant="outlined"
          size="small"
          value={start_time}

          onChange={(e)=>{
            setStart_time(e.target.value)
            }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
        <CssTextField
          label="End time"
          type="time"
          variant="outlined"
          size="small"
          value={end_time}
          onChange={(e)=>{
            setEnd_time(e.target.value)
            }}
          style={{marginLeft:50}}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      </div>
      <div style={{color:"red",marginTop:10}}>{error1}</div>
    </div>
  )

  const step2 = (
    <>
      {selectedcontrollers.length?
      <div style={{paddingTop:10}}>
        <div style={{fontWeight:'bold'}}>Selected Controllers</div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table" fullwidth>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.heading} >
                Name
              </StyledTableCell>
              <StyledTableCell className={classes.heading} >
                Ports
              </StyledTableCell>
              <StyledTableCell className={classes.heading} >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {selectedcontrollers.map((item) => (
              <StyledTableRow key={item._id}>
                <StyledTableCell component="th" scope="row">
                  {item.name?item.name:""}
                </StyledTableCell>
                <StyledTableCell >{item.ports.join()}</StyledTableCell>
                <StyledTableCell >
                  <Tooltip title="delete" style={{cursor:'pointer'}}><DeleteIcon onClick={()=>{handleUnselect(item._id)}} /></Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>

      </div>:null}

      <div style={{display:"flex",alignItems:"center",marginTop:10}}>
      <FormControl className={classes.formControl} variant="outlined" size="small" style={{marginTop:10}}>
        <InputLabel id="controllers">Select Controllers</InputLabel>
        <Select
          label="Select Controllers"
          labelId="controllers"
          id="demo-simple-select"
          value={current_controller}
          onChange={(e)=>setCurrent_controller(e.target.value)}
        >
          {controllers.map((item) => <MenuItem value={item._id} key={item._id}>{(item.name?item.name:"")+' ('+item.mac_addr+')'}</MenuItem>)
          }
       </Select>
      </FormControl>
          {current_controller &&
            <div style={{display:"flex",alignItems:"center",marginTop:20,marginLeft:50}}>
              
              {ports.map(item =>
                <div key={item.name} >
                  <FormControlLabel
                    style={{margin:0}}
                    control={<Checkbox
                      checked={item.value}
                      onChange={(e) => { handleChecked(item.name) }}
                      color="primary"
                    />}
                    label={item.name}
                    labelPlacement="top"
                  />
                  
                </div>
              )}
            </div>
          }
      </div>
      
      <div style={{color:"red",marginTop:10}}>{error2}</div>
      <Button onClick={handleSelect} color="primary" variant="outlined" style={{marginTop:5}}>Add+</Button>
    </>
  )


  const step3=(
    <>  
          <CssTextField onChange={(e)=>setOntime(e.target.value)} value={ontime} fullWidth variant="outlined" label={mode==='CYC'?"ON time":"Resume time"} size="small" 
          InputProps={{
            endAdornment: <InputAdornment position="end">sec (limit 10-9999)</InputAdornment>,
          }}/>
          <CssTextField onChange={(e)=>setOfftime(e.target.value)} value={offtime} fullWidth variant="outlined" label={mode==='CYC'?"OFF time":"Stop time"} size="small" style={{marginTop:20}}
          InputProps={{
            endAdornment: <InputAdornment position="end">min (limit 1-9999)</InputAdornment>,
          }}/>
          {mode==='AMB'?
          <>
          <div style={{ fontWeight: 'bold',fontSize:22,marginTop:20 }}>Conditions</div>
          {selectedcondition.length ?
            <div style={{ paddingTop: 10 }}>
                <div style={{ fontWeight: 600,fontSize:16 }}>Conditions Added</div>
                  <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="customized table" fullwidth>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell className={classes.heading} >
                              Condition
                            </StyledTableCell>
                            <StyledTableCell className={classes.heading} >
                              Actions
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>

                          {selectedcondition.map((item) => (
                            <StyledTableRow key={item._id}>
                              <StyledTableCell component="th" scope="row">
                                {` If ${item.type==='T'?"Temperature":"Humidity"} ${item.operator} ${(item.name ? item.name : "")} ${item.value}${item.type==='T'?"°C.":"%."}`}
                              </StyledTableCell>
                              <StyledTableCell >
                                <Tooltip title="delete" style={{cursor:'pointer'}}><DeleteIcon onClick={()=>{deleteCondition(item)}} /></Tooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                          }
                        </TableBody>
                      </Table>
                  </TableContainer>
            </div> : null}
          <div style={{border:"dotted grey 1px", padding:15,marginTop:20}}>
            <div style={{ fontWeight: 'bold',fontSize:18}}>Add New Condition</div>
            <div>
          <FormControl className={classes.formControl} style={{marginTop:20}} variant="outlined"  size="small" >
              <InputLabel  id="mode">Type</InputLabel>
              <Select
              
                label="Type"
                labelId="Operation"
                id="demo-simple-select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={"T"}>Temperature</MenuItem>
                <MenuItem value={"H"}>Humidity</MenuItem>
                <MenuItem value={"TH"}>Temperature and Humidity</MenuItem>
              </Select>

            </FormControl>
          </div>

          {type==='T'||type==='TH'?
          <div style={{display:"flex",marginTop: 30,alignItems:"center" }}>
          {"If Temperature is"}
          <FormControl className={classes.formControl} variant="outlined" size="small" style={{marginLeft:10}} >
              <Select
                value={toperator}
                onChange={(e) => setToperator(e.target.value)}
              >
                <MenuItem value={"LESS THAN EQUAL TO"}>LESS THAN EQUAL TO</MenuItem>
                <MenuItem value={"GREATER THAN EQUAL TO"}>GREATER THAN EQUAL TO</MenuItem>
              </Select>
          </FormControl>

          <CssTextField onChange={(e)=>setTemp(e.target.value)} value={temp} 
          style={{width:100,marginLeft:10}} variant="outlined" size="small"
          InputProps={{
            endAdornment:<InputAdornment position="end">°C</InputAdornment>,
          }}
            />
          </div>:null}

          {type==='H'||type==='TH'?
          <div style={{display:"flex",marginTop: 30,alignItems:"center" }}>
          {"If  Humidity  is"}
          <FormControl className={classes.formControl} variant="outlined" size="small" style={{marginLeft:30}} >
              <Select
                value={hoperator}
                onChange={(e) => setHoperator(e.target.value)}
              >
                <MenuItem value={"LESS THAN EQUAL TO"}>LESS THAN EQUAL TO</MenuItem>
                <MenuItem value={"GREATER THAN EQUAL TO"}>GREATER THAN EQUAL TO</MenuItem>
              </Select>
          </FormControl>

          <CssTextField onChange={(e)=>setHum(e.target.value)} value={hum} 
          style={{width:100,marginLeft:10}} variant="outlined" size="small"
          InputProps={{
            endAdornment:<InputAdornment position="end">%</InputAdornment>,
          }}
            />
          </div>:null}
          <div style={{color:"red",marginTop:10}}>{error3}</div>
          <div style={{display:"flex",marginTop: 20,alignItems:"center" }}>
          
          <Button onClick={handleSelectconditions} color="primary" variant="outlined" >Add+</Button>
          </div>
          
          </div>
          <div>
          <div  style={{ fontWeight: 'bold',fontSize:18,marginTop:10}}>For Sensors</div>
          {selectedsensors.length ?
            <div style={{ paddingTop: 10 }}>
                <div style={{ fontWeight: 500,fontSize:15 }}>Sensors Added</div>
              
              <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" fullwidth>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className={classes.heading} >
                            Sensor
                          </StyledTableCell>
                          <StyledTableCell className={classes.heading} >
                            Actions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedsensors.map((item) => (
                          <StyledTableRow key={item._id}>
                            <StyledTableCell component="th" scope="row">
                              {item.name ? item.name : ""}
                            </StyledTableCell>
                            <StyledTableCell >
                              <Tooltip title="delete" style={{cursor:'pointer'}}><DeleteIcon onClick={()=>{deleteSensor(item._id)}} /></Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                        }
                      </TableBody>
                    </Table>
                </TableContainer>
            </div> : null}
            </div>
          <div style={{ border: "dotted grey 1px", padding: 15, marginTop: 20 }}>
          <div style={{ fontWeight: 'bold',fontSize:18}}>Add New Sensor</div>
            <div style={{marginTop:10,display:"flex",alignItems:"center"}}>
            <FormControl variant="outlined" size="small" style={{ minWidth: 200, marginLeft: 10 }} >
              <InputLabel id="sensors">Sensors</InputLabel>
              <Select
                label="Sensors"
                labelId="sensors"
                value={current_sensor}
                onChange={(e) => setCurrent_sensor(e.target.value)}
              >
                {sensors.map((item) => <MenuItem value={item._id} key={item.serial_num}>
                  {(item.name ? item.name : "") + ' (' + item.serial_num + ')'}</MenuItem>)
                }
              </Select>
            </FormControl>
            <Button onClick={handleSelectsensors} color="primary" variant="outlined" style={{ marginLeft: 20 }}>Add+</Button>
            </div>
          </div>
          
         
          </>
          :
          null
          }
           <div style={{color:"red",marginTop:10}}>{error3}</div>
    </>
  )

  const body = (
    <div  className={classes.paper}>
      <CloseIcon style={{position:"absolute",top:10,right:10,cursor:"pointer"}} onClick={closeModal}/>
      <h2 >Please Enter Task Details</h2>
      
      <Stepper  alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div >
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            <div style={{marginTop:50}}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === steps.length - 1?(updatetaskid.length? handleUpdate:handleSubmit):handleNext}
                className={classes.button}
              >
                
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
      </div>
    </div>
  );

  function getStepContent(step) {
    switch (step) {
      case 0:
        return step1;
      case 1:
        return step2;
      case 2:
        return step3;
      default:
        return 'Unknown step';
    }
  }

  return (
    <Container>
      <h3 style={{ color: "#50A84C" }}>Tasks</h3>
      <div>
        <div className={classes.add}>
          <Button color="primary" variant="text" size="large" onClick={handleModal}>
            <Box
              style={{
                fontWeight: 500,
                fontSize: 18,
                textTransform: "none",
                padding: 0,
              }}
            >
              +Add Task
            </Box>
          </Button>
        </div>
        <div>
          <TaskTable  tasks={tasks} loader={loader} getfarmdata={getfarmdata}
           setLoader={setLoader} handleDelete={handleDelete} handleEdit={handleEdit}/>
        </div>
      </div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          {body}
      </Modal>
      <Snackbar open={snackbar} autoHideDuration={4000} onClose={()=>setSnackbar(false)}>
        <Alert onClose={()=>setSnackbar(false)} severity="success">
          Task added!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TaskView;
